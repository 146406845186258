import { $API } from "boot/api";
import moment from "moment";

const isActionable = (notification) => {
  return (
    notification.data.auction_event === "auction_transfer_not_started" ||
    (notification.data.title &&
      notification.data.title.toLowerCase().includes("action needed"))
  );
};

const isOlderThan4Weeks = (notification) => {
  const fourWeeksAgo = new Date();
  fourWeeksAgo.setDate(fourWeeksAgo.getDate() - 28); // 4 weeks = 28 days

  const notificationDate = new Date(notification.created_on);
  return notificationDate < fourWeeksAgo;
};

const filterOutdatedNotifications = (notifications) => {
  return notifications.filter((notification) => {
    if (notification.is_read) return true;

    if (!isActionable(notification) && isOlderThan4Weeks(notification)) {
      return false;
    }

    return true;
  });
};

export function sync({ commit, dispatch }) {
  return $API.CURRENT_USER_NOTIFICATIONS.GET({
    type: "unread",
  })
    .then(({ data }) => {
      const filteredNotifications = filterOutdatedNotifications(
        data.notifications
      );
      commit("setNotifications", filteredNotifications);
      return filteredNotifications;
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("sync");
      }, 300);

      return [];
    });
}

export function pushNotification({ commit, state }, notification) {
  let notifications = [...state.notifications];
  notifications.unshift({
    created_on: moment().format("YYYY-MM-DD HH:mm:ss"),
    data: notification,
    is_read: false,
  });
  commit("setNotifications", notifications);
}

export function removeNotification({ commit, state }, notificationId) {
  let index = state.notifications.findIndex(
    (notification) => notification.data.id === notificationId
  );
  if (index >= 0) {
    let notifications = [...state.notifications];
    notifications.splice(index, 1);
    commit("setNotifications", notifications);
  }
}

export function markAllRead({ commit }) {
  $API.CURRENT_USER_NOTIFICATIONS.POST("mark-read").then(({ data }) => {
    commit("setNotifications", data.notifications);
  });
}

export function markRead({ commit }, notificationId) {
  $API.CURRENT_USER_NOTIFICATIONS.POST(`${notificationId}/mark-read`).then(
    ({ data }) => {
      commit("updateNotification", data.notification);
    }
  );
}
