const isActionable = (notification) => {
  return (
    notification.data.auction_event === "auction_transfer_not_started" ||
    (notification.data.title &&
      notification.data.title.toLowerCase().includes("action needed"))
  );
};

const isOlderThan4Weeks = (notification) => {
  const fourWeeksAgo = new Date();
  fourWeeksAgo.setDate(fourWeeksAgo.getDate() - 28);

  const notificationDate = new Date(notification.created_on);
  return notificationDate < fourWeeksAgo;
};

export function unread(state) {
  return state.notifications.filter((notification) => {
    if (notification.is_read) return false;

    if (!isActionable(notification) && isOlderThan4Weeks(notification)) {
      return false;
    }

    return true;
  });
}

//TODO: replace with an api call to the database
export function search(state) {
  const re = new RegExp(
    `\\b(${state.searchValue
      .split(" ")
      .filter((word) => word)
      .join("|")})\\b`,
    "gi"
  );
  const count = (str) => (str.replace(/[^a-z]/g, " ").match(re) || []).length;
  const arr = state.notifications
    .map((notification) => {
      let data = {};
      data.count = count(notification.data.title) * 2;
      data.count += count(notification.data.text);
      data.notification = notification;

      return data;
    })
    .filter((data) => data.count)
    .sort((a, b) => (a.count > b.count ? -1 : 1))
    .map((data) => data.notification);

  return arr;
}
