export function setFormValue(state, { prop, value }) {
  state.form[prop] = value;
}

export function mapFormData(state, data) {
  state.form.id = data.id;
  state.form.title = data.name;
  state.form.logo = data.logo;
  state.form.chat = NaN;
  state.form.banner = data.banner;
  state.form.product_sections = data.product_sections;
}
export function clearFormData(state) {
  state.form = {
    chat: 0,
    title: "",
    logo: null,
    banner: null,
    product_sections: [],
  };
}

export function addProductSection(state, section) {
  state.form.product_sections.push(section);
}
