import { $API } from "boot/api";

export function getFormData(state) {
  const { title, logo, banner, product_sections } = state.form;

  //TODO: fix naming conventions to be be congruent
  return {
    name: title,
    logo_file: logo,
    banner_file: banner,
    product_sections: product_sections,
  };
}
