export function getBrandById(state) {
  return (brandId) => {
    return state.allBrands[brandId];
  };
}

export function getTotalItemsForBrand(state) {
  return (brandId) => {
    const auctions = state.auctionsByBrand[brandId] || [];
    const listings = state.listingsByBrand[brandId] || [];

    return auctions.length + listings.length;
  };
}

export function getTotalItemsForProductLine(state) {
  return (productLineId) => {
    let total = 0;

    Object.values(state.auctionsByBrand).forEach((auctions) => {
      total += auctions.filter(
        (auction) => auction.product_line?.id === productLineId
      ).length;
    });

    Object.values(state.listingsByBrand).forEach((listings) => {
      total += listings.filter(
        (listing) => listing.product_line?.id === productLineId
      ).length;
    });

    Object.values(state.productsByBrand).forEach((products) => {
      total += products.filter(
        (product) => product.product_line?.id === productLineId
      ).length;
    });

    return total;
  };
}

export function getProductLineById(state) {
  return (productLineId) => {
    const productLines = state.productLines || [];
    return productLines.find((line) => line.id === productLineId);
  };
}
