export default {
  form: {
    chat: 0,
    action: null,
    no_listing: false,

    buyer_request_title: "",
    buyer_request_description: "",
    buyer_request_image: null,
    buyer_request_category_type: null,
    buyer_request_category: null,
    buyer_request_retail_type: null,
    buyer_request_thc_cbd_range: false,
    buyer_request_thc_value: null,
    buyer_request_thc_value_max: null,
    buyer_request_thc_unit: "%",
    buyer_request_cbd_value: null,
    buyer_request_cbd_value_max: null,
    buyer_request_cbd_unit: "%",
    buyer_request_packaged_qty_value: null,
    buyer_request_packaged_qty_unit: null,
    buyer_request_production_type: null,
    buyer_request_inventory_value: null,
    buyer_request_inventory_unit: "g",
    buyer_request_target_price: null,
    buyer_request_max_price: null,

    auction_product: null,
    auction_item: null,
    auction_category_type: null,
    auction_category: null,
    auction_retail_type: null,
    auction_is_new_brand: null,
    auction_brand: null,
    auction_brand_logo: null,
    auction_brand_name: "",
    auction_title: "",
    auction_description: "",
    auction_images: [],
    auction_thc_cbd_range: false,
    auction_thc_value: null,
    auction_thc_value_max: null,
    auction_thc_unit: "%",
    auction_cbd_value: null,
    auction_cbd_value_max: null,
    auction_cbd_unit: "%",
    auction_packaged_qty_value: '',
    auction_packaged_qty_unit: '',
    auction_strain_type: null,
    auction_trim_type: null,
    auction_trim_machine: null,
    auction_dry_method: null,
    auction_production_type: null,
    auction_production_date: null,
    auction_is_coa_file: null,
    auction_coa_files: [],
    auction_is_product_expire: null,
    auction_expiration_date: null,
    auction_package: "",
    auction_is_list_all: null,
    auction_inventory_value: null,
    auction_inventory_unit: null,
    auction_new_packages: [],
    auction_start_price_per_unit: 1.00,
    auction_start_price: null,
    auction_is_buy_now_price: null,
    auction_buy_now_price_per_unit: null,
    auction_buy_now_price: null,
    auction_is_reserve_price: null,
    auction_reserve_price_per_unit: null,
    auction_reserve_price: null,
    auction_is_promoted: null,
    auction_is_auto_relist: null,
    auction_is_start_now: null,
    auction_start_date: "",
    auction_days_duration: null,
    auction_payment_terms: [],
    auction_grow_media: [],
    auction_shipping_boxes: [],
    auction_shipping_boxes_value: {},
    auction_target_price: null,
    auction_min_price: null,
    auction_is_available_for_sale: null
  }
}
