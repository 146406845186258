export function setBrands(state, brands) {
  let tmpBrands = [];
  brands.forEach((brand) => {
    if (!brand.is_archived) {
      tmpBrands.push({
        id: brand.id,
        name: brand.name,
        code: brand.code,
        logo_url: brand.logo_url,
        banner_url: brand.banner_url,
        status: brand.status,
        product_sections: brand.product_sections,
      });
    }
  });
  state.brands = tmpBrands;
}
export function setAllBrands(state, brands) {
  const brandsMap = {};
  brands.forEach((brand) => {
    if (!brand.is_archived) {
      brandsMap[brand.id] = {
        id: brand.id,
        name: brand.name,
        code: brand.code,
        logo_url: brand.logo_url,
        banner_url: brand.banner_url,
        product_sections: brand.product_sections,
        isUserBrand: false,
      };
    }
  });
  state.allBrands = brandsMap;
}
export function setUserBrands(state, userBrandIds) {
  userBrandIds.forEach((id) => {
    if (state.allBrands[id]) {
      state.allBrands[id].isUserBrand = true;
    }
  });
}
export function setBrandDetails(state, { brandId, details }) {
  state.brandDetails[brandId] = details;
}
export function setAuctions(state, auctions) {
  state.auctions = auctions;
}
export function setListings(state, listings) {
  state.listings = listings;
}
export function setAuctionsForBrand(state, { brandId, auctions }) {
  state.auctionsByBrand[brandId] = auctions;
}
export function setListingsForBrand(state, { brandId, listings }) {
  state.listingsByBrand[brandId] = listings;
}
export function setCategoryImages(state, category_images) {
  state.category_images = category_images;
}
export function setCategoryTypes(state, category_types) {
  state.category_types = category_types;
}
export function setCategories(state, categories) {
  state.categories = categories;
}
export function setFilterCategories(state, categories) {
  state.inventory_filter_categories = categories;
}
export function setTrimTypes(state, trim_types) {
  state.trim_types = trim_types;
}
export function setStrainTypes(state, strain_types) {
  state.strain_types = strain_types;
}
export function setIngredients(state, ingredients) {
  state.ingredients = ingredients;
}
export function setProductionTypes(state, production_types) {
  state.production_types = production_types;
}
export function setProducts(state, products) {
  state.products = products;
}

/** License mutations **/
export function setLicenses(state, licenses) {
  state.licenses = licenses;
}
export function updateLicense(state, updated_license) {
  let index = state.licenses.findIndex(
    (license) => license.id === updated_license.id
  );
  if (index >= 0) {
    state.licenses[index] = updated_license;
  } else {
    state.licenses.push(updated_license);
  }
}
export function addLicense(state, license) {
  state.licenses.push(license);
}
export function removeLicense(state, license_to_remove) {
  let index = state.licenses.findIndex(
    (license) => license.id === license_to_remove.id
  );
  if (index >= 0) {
    state.licenses.splice(index, 1);
  }
}

/** Payment methods mutations **/
export function setPaymentMethods(state, paymentMethods) {
  state.payment_methods = paymentMethods;
}
export function updatePaymentMethod(state, updatedPaymentMethod) {
  let index = state.payment_methods.findIndex(
    (pm) => pm.id === updatedPaymentMethod.id
  );
  if (index >= 0) {
    state.payment_methods[index] = updatedPaymentMethod;
  } else {
    state.payment_methods.push(updatedPaymentMethod);
  }
}
export function addPaymentMethod(state, license) {
  state.payment_methods.push(license);
}
export function removePaymentMethod(state, paymentMethodToRemove) {
  let index = state.payment_methods.findIndex(
    (pm) => pm.id === paymentMethodToRemove.id
  );
  if (index >= 0) {
    state.payment_methods.splice(index, 1);
  }
}

export function addBrand(state, brand) {
  state.brands.push(brand);
}
export function addProduct(state, product) {
  if (!state.products.some((p) => p.id === product.id)) {
    state.products.push(product);
  }

  if (product.brand) {
    if (!state.productsByBrand[product.brand.id]) {
      state.productsByBrand[product.brand.id] = [];
    }
    if (
      !state.productsByBrand[product.brand.id].some((p) => p.id === product.id)
    ) {
      state.productsByBrand[product.brand.id].push(product);
    }
  }
}
export function updateProduct(state, product) {
  const productIndex = state.products.findIndex((p) => p.id === product.id);
  if (productIndex !== -1) {
    state.products.splice(productIndex, 1, product);
  }

  if (product.brand) {
    const brandProducts = state.productsByBrand[product.brand.id];
    if (brandProducts) {
      const brandProductIndex = brandProducts.findIndex(
        (p) => p.id === product.id
      );
      if (brandProductIndex !== -1) {
        brandProducts.splice(brandProductIndex, 1, product);
      }
    }
  }
}
export function updateListingsForBrand(state, { brandId, listings }) {
  if (!state.listingsByBrand[brandId]) {
    state.listingsByBrand[brandId] = [];
  }

  listings.forEach((listing) => {
    const exists = state.listingsByBrand[brandId].some(
      (existing) => existing.id === listing.id
    );
    if (!exists) {
      state.listingsByBrand[brandId].push(listing);
    }
  });
}
// export function updateProduct(state, updated_product) {
//   let index = state.products.findIndex(
//     (product) => product.id === updated_product.id
//   );
//   if (index >= 0) {
//     state.products[index] = updated_product;
//   } else {
//     state.products.push(updated_product);
//   }
// }

export function setCompany(state, value) {
  state.company = value;
}
//export function setLicenses(state, licenses) {
//  state.licenses = licenses;
//}
//export function addLicense(state, license) {
//  state.licenses.push(license);
//}
//export function removeLicense(state, licenseId) {
//  let index = state.licenses.findIndex((license) => license.id === licenseId);
//  if (index >= 0) {
//    state.licenses.splice(index, 1);
//  }
//}
export function setCompanyContacts(state, value) {
  state.company_contacts = value;
}
export function setOrderStatuses(state, value) {
  state.order_statuses = value;
}
export function setOrderSources(state, value) {
  state.order_sources = value;
}
export function setOrderTypes(state, value) {
  state.order_types = value;
}
export function updateOrAddCompanyContact(state, contact) {
  let index = state.company_contacts.findIndex((c) => c.id === contact.id);
  if (index >= 0) {
    state.company_contacts[index] = contact;
  } else {
    state.company_contacts.push(contact);
  }
}
export function removeCompanyContact(state, contactId) {
  let index = state.company_contacts.findIndex((c) => c.id === contactId);
  if (index >= 0) {
    state.company_contacts.splice(index, 1);
  }
}
export function setUsers(state, users) {
  state.users = users;
}
export function addUser(state, user) {
  state.users.push(user);
}
export function removeUser(state, user_id) {
  let index = state.users.findIndex((user) => user.id === user_id);
  if (index >= 0) {
    state.users.splice(index, 1);
  }
}
export function addTempBox(state, box) {
  state.company.tempBoxes.push(box);
}
export function editTempBox(state, box) {
  let index = state.company.tempBoxes.findIndex((el) => (el.id = box.id));
  state.company.tempBoxes[index] = box;
}
export function deleteTempBox(state, box) {
  let index = state.company.tempBoxes.findIndex((el) => (el.id = box.id));
  state.company.tempBoxes.splice(index, 1);
}
export function clearTempShippingBoxes(state) {
  state.company.tempBoxes = [];
}
export function setPaymentTerms(state, paymentTerms) {
  state.payment_terms = paymentTerms;
}
export function setGrowMedia(state, growMedia) {
  state.grow_media = growMedia;
}
export function setLocationNames(state, locationName) {
  state.location_names = locationName;
}
export function setInventoryCategoryTypes(state, categoryTypes) {
  state.inventory_category_types = categoryTypes;
}
export function setLoading(state, isLoading) {
  state.isLoadingData = isLoading;
}
export function setProductsForBrand(state, { brandId, products }) {
  if (!state.productsByBrand[brandId]) {
    state.productsByBrand[brandId] = [];
  }

  state.productsByBrand[brandId] = [...products];
}
export function setBrandLoading(state, { brandId, isLoading }) {
  state.brandLoadingStates = {
    ...state.brandLoadingStates,
    [brandId]: isLoading,
  };
}
export function setLastBrandUpdate(state, timestamp) {
  state.lastBrandUpdate = timestamp;
}

export function setAvailablePackageTags(state, { licenseId, tags }) {
  state.availablePackageTags[licenseId] = tags;
}

export function removePackageTag(state, { licenseId, label }) {
  if (state.availablePackageTags[licenseId]) {
    state.availablePackageTags[licenseId] = state.availablePackageTags[
      licenseId
    ].filter((tag) => tag.Label !== label);
  }
}

export function setProductLines(state, productLines) {
  state.productLines = productLines;
}

export function updateBrandProductSection(state, { brandId, productSection }) {
  if (state.allBrands[brandId]) {
    if (!state.allBrands[brandId].product_sections) {
      state.allBrands[brandId].product_sections = [];
    }

    const index = state.allBrands[brandId].product_sections.findIndex(
      (section) => section.id === productSection.id
    );

    if (index !== -1) {
      state.allBrands[brandId].product_sections.splice(
        index,
        1,
        productSection
      );
    } else {
      state.allBrands[brandId].product_sections.push(productSection);
    }
  }
}

export function removeBrandProductSection(state, { brandId, sectionId }) {
  if (state.allBrands[brandId] && state.allBrands[brandId].product_sections) {
    const index = state.allBrands[brandId].product_sections.findIndex(
      (section) => section.id === sectionId
    );

    if (index !== -1) {
      state.allBrands[brandId].product_sections.splice(index, 1);
    }
  }
}

export function updateProductSection(state, { productId, section }) {
  Object.keys(state.auctionsByBrand).forEach((brandId) => {
    if (state.auctionsByBrand[brandId]) {
      state.auctionsByBrand[brandId].forEach((auction) => {
        if (auction.product_id === productId || auction.id === productId) {
          auction.product_section_id = section.id;
          auction.product_section = section;

          if (auction.product) {
            auction.product.product_section = section;
            auction.product.product_section_id = section.id;
          }
        }
      });
    }
  });

  Object.keys(state.listingsByBrand).forEach((brandId) => {
    if (state.listingsByBrand[brandId]) {
      state.listingsByBrand[brandId].forEach((listing) => {
        if (listing.product_id === productId || listing.id === productId) {
          listing.product_section_id = section.id;
          listing.product_section = section;

          if (listing.product) {
            listing.product.product_section = section;
            listing.product.product_section_id = section.id;
          }
        }
      });
    }
  });

  Object.keys(state.productsByBrand).forEach((brandId) => {
    if (state.productsByBrand[brandId]) {
      state.productsByBrand[brandId].forEach((product) => {
        if (product.id === productId) {
          product.product_section = section;
          product.product_section_id = section.id;
        }
      });
    }
  });

  if (state.products) {
    state.products.forEach((product) => {
      if (product.id === productId) {
        product.product_section = section;
        product.product_section_id = section.id;
      }
    });
  }

  try {
    const key = `brand_${section.brand_id}_sections`;
    const assignments = JSON.parse(localStorage.getItem(key) || "{}");
    assignments[productId] = section.id;
    localStorage.setItem(key, JSON.stringify(assignments));
  } catch (e) {
    console.error("Error saving to localStorage:", e);
  }
}

export function updateProductSectionWithoutStorage(
  state,
  { productId, section }
) {
  Object.keys(state.auctionsByBrand).forEach((brandId) => {
    if (state.auctionsByBrand[brandId]) {
      state.auctionsByBrand[brandId].forEach((auction) => {
        if (auction.product_id === productId || auction.id === productId) {
          auction.product_section_id = section.id;
          auction.product_section = section;

          if (auction.product) {
            auction.product.product_section = section;
            auction.product.product_section_id = section.id;
          }
        }
      });
    }
  });

  Object.keys(state.listingsByBrand).forEach((brandId) => {
    if (state.listingsByBrand[brandId]) {
      state.listingsByBrand[brandId].forEach((listing) => {
        if (listing.product_id === productId || listing.id === productId) {
          listing.product_section_id = section.id;
          listing.product_section = section;

          if (listing.product) {
            listing.product.product_section = section;
            listing.product.product_section_id = section.id;
          }
        }
      });
    }
  });

  Object.keys(state.productsByBrand).forEach((brandId) => {
    if (state.productsByBrand[brandId]) {
      state.productsByBrand[brandId].forEach((product) => {
        if (product.id === productId) {
          product.product_section = section;
          product.product_section_id = section.id;
        }
      });
    }
  });

  if (state.products) {
    state.products.forEach((product) => {
      if (product.id === productId) {
        product.product_section = section;
        product.product_section_id = section.id;
      }
    });
  }
}

export function loadProductSectionAssignments(state, brandId) {
  try {
    const key = `brand_${brandId}_sections`;
    const assignments = JSON.parse(localStorage.getItem(key) || "{}");
    console.log("Loading section assignments for brand:", brandId, assignments);

    Object.entries(assignments).forEach(([productId, sectionId]) => {
      const section = state.brands[brandId]?.product_sections?.find(
        (s) => s.id === Number(sectionId)
      );
      if (section) {
        const params = {
          productId: Number(productId),
          section,
        };
        updateProductSectionWithoutStorage(state, params);
      }
    });
  } catch (e) {
    console.error("Error loading section assignments:", e);
  }
}
